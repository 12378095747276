<script>
	import { matches } from '$lib/stores/mediaQueries.js';
	import Review from './Review.svelte';
	import reviewData from '$lib/data/reviews.json';
	import ArrowLeftSvg from '$lib/assets/images/arrow-left.svg';
	import ArrowRightSvg from '$lib/assets/images/arrow-right.svg';

	const reviewCount = reviewData.length;
	const numReviews = $matches?.mobile ? 1 : $matches?.tablet ? 2 : 3;
	let currentReview = 0;
	let interval;
	let carousel;
	let touchStartX, touchCurrentX, touchEndX, swipeDistance;

	function loadNextReview(numberOfReviews = 1) {
		currentReview = (currentReview + numberOfReviews) % reviewCount;
		resetCarouselTransform();
		resetInterval();
	}

	function loadPreviousReview(numberOfReviews = 1) {
		currentReview = (currentReview - numberOfReviews + reviewCount) % reviewCount;
		resetCarouselTransform();
		resetInterval();
	}

	function resetCarouselTransform() {
		requestAnimationFrame(() => {
			if (carousel) {
				carousel.style.transform = '';
			}
		});
	}

	const resetInterval = () => {
		clearInterval(interval);
		interval = setInterval(() => {
			loadNextReview();
		}, 10000);
	};

	function handleSwipe(event) {
		resetInterval();
		switch (event.type) {
			case 'touchstart':
				touchStartX = event.touches[0].clientX;
				break;
			case 'touchmove':
				touchCurrentX = event.touches[0].clientX;
				swipeDistance = touchStartX - touchCurrentX;
				requestAnimationFrame(() => {
					carousel.style.transform = `translateX(${-swipeDistance}px)`;
				});
				break;
			case 'touchend':
				touchEndX = event.changedTouches[0].clientX;
				swipeDistance = touchStartX - touchEndX;
				if (swipeDistance > 50) {
					loadNextReview(numReviews);
				} else if (swipeDistance < -50) {
					loadPreviousReview(numReviews);
				} else {
					resetCarouselTransform();
				}
				break;
		}
	}
</script>

<section>
	<div class="title">
		<p>Find out what sets us apart</p>
		<h2>Reviews from our happy customers</h2>
	</div>
	<div
		class:carousel
		on:touchstart|passive={handleSwipe}
		on:touchmove|passive={handleSwipe}
		on:touchend|passive={handleSwipe}
		on:touchcancel|passive={handleSwipe}
		bind:this={carousel}
	>
		{#if $matches?.mobile}
			<Review id={currentReview}>
				<span slot="title">{reviewData[currentReview].title}</span>
				<span slot="text">{reviewData[currentReview].text}</span>
				<span slot="author">{reviewData[currentReview].name}</span>
			</Review>
		{:else if $matches?.tablet}
			<Review id={currentReview}>
				<span slot="title">{reviewData[currentReview].title}</span>
				<span slot="text">{reviewData[currentReview].text}</span>
				<span slot="author">{reviewData[currentReview].name}</span>
			</Review>
			<Review id={currentReview + 1}>
				<span slot="title">{reviewData[(currentReview + 1) % reviewData.length].title}</span>
				<span slot="text">{reviewData[(currentReview + 1) % reviewData.length].text}</span>
				<span slot="author">{reviewData[(currentReview + 1) % reviewData.length].name}</span>
			</Review>
		{:else if $matches?.desktop}
			<button on:click={() => loadPreviousReview(numReviews)} aria-label="Previous Review">
				<img src={ArrowLeftSvg} alt="Arrow Left" width="14" height="16" />
			</button>
			<Review id={currentReview}>
				<span slot="title">{reviewData[currentReview].title}</span>
				<span slot="text">{reviewData[currentReview].text}</span>
				<span slot="author">{reviewData[currentReview].name}</span>
			</Review>
			<Review id={currentReview + 1}>
				<span slot="title">{reviewData[(currentReview + 1) % reviewData.length].title}</span>
				<span slot="text">{reviewData[(currentReview + 1) % reviewData.length].text}</span>
				<span slot="author">{reviewData[(currentReview + 1) % reviewData.length].name}</span>
			</Review>
			<Review id={currentReview + 2}>
				<span slot="title">{reviewData[(currentReview + 2) % reviewData.length].title}</span>
				<span slot="text">{reviewData[(currentReview + 2) % reviewData.length].text}</span>
				<span slot="author">{reviewData[(currentReview + 2) % reviewData.length].name}</span>
			</Review>
			<button on:click={() => loadNextReview(numReviews)} aria-label="Next Review">
				<img src={ArrowRightSvg} alt="Arrow Left" width="14" height="16" />
			</button>
		{/if}
	</div>
</section>

<style lang="scss">
	section {
		margin-top: 4em;
	}
	.title {
		text-align: center;
		h2 {
			margin: 0.3em;
		}
		p {
			margin: 0;
			color: #666;
		}
	}
	.carousel {
		display: flex;
		justify-content: center;
		overflow: hidden;
		position: relative;

		button {
			background: #fff;
			border: 1px solid #eaeaea;
			border-radius: 1000%;
			height: min-content;
			padding: 1em 1.2em;
			margin: 0 0.5em;
			cursor: pointer;
			align-self: center;

			&:hover {
				background: #eee;
			}
		}
	}
</style>
