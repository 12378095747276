<script>
	import { fly } from 'svelte/transition';
	import { backOut } from 'svelte/easing';
	import starSvg from '$lib/assets/images/star.svg';
	import circleCheckSvg from '$lib/assets/images/circle-check.svg';

	export let id = 0;

	let previousId = 0;
	let direction = 200;
	let delay = 0;

	$: {
		direction = id < previousId ? -200 : 200;
		previousId = id;
		delay = id * 100;
	}
</script>

{#key id}
	<div
		class="review-card"
		in:fly|global={{ duration: 300, x: direction, easing: backOut, delay: delay }}
	>
		<div class="review-header">
			<div class="review-rating">
				{#each Array(5) as _}
					<img src={starSvg} alt="star" width="18" height="16" />
				{/each}
			</div>
			<div class="review-title"><slot name="title" /></div>
		</div>
		<div class="review-text-container">
			<slot name="text" />
		</div>
		<div class="review-footer">
			<span>
				<img src={circleCheckSvg} alt="circle check" width="20" height="20" />
			</span>
			<div class="review-author"><slot name="author" /></div>
		</div>
	</div>
{/key}

<style>
	.review-card {
		display: flex;
		flex-direction: column;
		padding: 2em;
		width: 33%;
		border: 1px solid #eaeaea;
		margin: 1em;
		border-radius: 0.5em;
		justify-content: space-between;
		height: 25em;
	}
	.review-title {
		font-size: 1.3em;
		font-weight: 900;
	}
	.review-rating {
		margin: 1em 0 0.5em 0;
	}
	.review-footer {
		display: flex;
		border-top: 1px solid #eaeaea;
		margin-top: 1em;
		padding-top: 2em;
	}
	.review-author {
		margin-left: 0.5em;
		color: #666;
	}

	.review-text-container {
		margin-top: 1.5em;
		margin-bottom: 1em;
		height: 100%;
		padding-right: 1em;
		overflow-y: auto;
		text-overflow: ellipsis;
	}

	.review-text-container::-webkit-scrollbar {
		width: 3px;
		height: 100%;
	}

	.review-text-container::-webkit-scrollbar-thumb {
		background-color: #e8e8e8;
		border-radius: 0;
	}

	/* Tablet */

	@media (max-width: 1024px) {
		.review-card {
			width: 45%;
		}
	}

	/* Mobile */

	@media (max-width: 767px) {
		.review-card {
			width: 80%;
		}
	}
</style>
